import React from 'react'
import styled from 'styled-components'

export const ObjectiveDropDownWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: -13px;
`

export const ObjectiveSelected = styled.p`
    flex: 1;
    margin: 0;
    font-weight: 200;
    color: rgba(255,255,255,0.5);

    &:hover{
        color:#15afd0;
    }
`

export const ObjectiveIcon = styled.div`
    width: 15px;
    height: 15px;
    margin-left: 2px;
    margin-top:-1px;
`

export const ObjectiveMenu = styled.ul`
    position: absolute;
    top: 100%;
    z-index: 9;
    background: #000;
    border-radius: 5px;
    height: auto;
    margin:0;
    padding:0;
    list-style-type:none;
    min-width: 175px;
    left:-10px;
    margin-top: 5px;
`

export const ObjectiveItem = styled.li`
    padding: 7px 10px;
    border-bottom: 1px solid #292929;
    font-size: 14px;
    color:rgba(255,255,255,0.5);

    &:last-child{
        border-bottom:0px;
    }

    &:hover{
        color:#15afd0;
    }
`

export const SubMenuItem = styled.a`
    padding: 0.8rem 1rem;
    margin: 0px 0.188rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 2px solid transparent;
    transition: background ease 500ms;
    cursor: pointer;
    text-decoration: none;
    font-weight: 200;
    margin-bottom: 2px;

    &:hover{
        > p{
            color:#15afd0;
        }
    }

    ${props => {
        if(props.active){
            return `
                > p{
                    color: #15afd0;
                }
            `
        }
    }}
`