import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory, Link } from "react-router-dom";

import { Header, Components, PageWrapper } from "./builder-components";

export default () => {
  const [showHamburgerMenu, setHamburgerMenu] = useState(false);

  const history = useHistory();

  return (
    <PageWrapper showHamburgerMenu={showHamburgerMenu}>
      <Header
        showHamburgerMenu={showHamburgerMenu}
        setHamburgerMenu={setHamburgerMenu}
      />
      <div style={{ width: "100%", maxWidth: "calc(111vw - 67px)" }}>
        <Components.ProductList
          removeContainer
          source={`/landing/configs/product.json`}
          title={"Products"}
          elementSize={4}
        />
      </div>
    </PageWrapper>
  );
};
