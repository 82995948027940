import { Button, Carousel as LibCarousel, Col, Row, Space, Drawer } from "antd";
import React, { useState } from "react";
import { Typography } from "antd";
import { colors } from "../../../legacy/components/chart";
import Axios from "axios";
import { Menu as Hamburger } from "styled-icons/boxicons-regular/Menu";
import {
  LogoName,
  LogoStatic,
  LogoDynamic,
  Sidebar,
  ScrollContainer,
  Logo,
  SubMenuItem,
  MenuText,
  Body,
  PageContainer,
} from "../styles";
import { PlayCircleFilled, RightOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { Link, useHistory } from "react-router-dom";
import { LandingConfig, LandingConfig as MenuConfig } from "../config";
import Menu from "../../../components/menu";
import { Container as ParentContainer } from "../styles";

export const Container = (props) => (
  <div
    style={{
      height: "100%",
      maxWidth: props.fullWidth ? "100%" : props.small ? "70%" : "85%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: props.fullWidth ? 65 : 0,
      paddingRight: props.fullWidth ? 65 : 0,
    }}
  >
    {props.children}
  </div>
);

export const TextWithContentSlide = (props) => {
  return (
    <div
      style={{
        height: "100%",
        position: "relative",
      }}
    >
      <Container fullWidth>
        <Row style={{ height: "100%", position: "static" }} align="middle">
          {props.right && (
            <Col span={14} style={{ position: "static" }}>
              {props.children}
            </Col>
          )}
          <Col
            span={10}
            gutter={50}
            style={{
              padding: props.fullWidth ? 0 : 0,
              position: "relative",
              zIndex: 9,
            }}
          >
            {props.miniHeader && (
              <Typography.Paragraph
                style={{
                  fontSize: "14px",
                  fontFamily: "SF Display",
                  marginBottom: 18,
                  letterSpacing: "1px",
                  color: colors[0],
                }}
              >
                {props.miniHeader}
              </Typography.Paragraph>
            )}
            <Typography.Title
              style={{
                fontSize: props.topOffset ? "42px" : "42px",
                fontFamily: "SF Display",
                color: "#FFF",
                marginBottom: 20,
                lineHeight: "1.2",
                marginTop: 0,
                letterSpacing: "-0.012em",
                fontWeight: "500",
              }}
              bold
            >
              {props.title}
            </Typography.Title>
            <Typography.Paragraph
              style={{
                fontSize: "18px",
                lineHeight: "1.7",
                fontWeight: "200",
                color: "#FFF",
                opacity: 0.8,
              }}
            >
              {props.description}
            </Typography.Paragraph>
          </Col>
          {!props.right && (
            <Col span={14} style={{ position: "static" }}>
              {props.children}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export const TextWithImageSlide = (props) => {
  return (
    <TextWithContentSlide {...props}>
      <div
        style={{
          position: "absolute",
          left: "50%",
          right: 0,
          top: 0,
          bottom: 0,
          backgroundImage: `url(${process.env.REACT_APP_S3_BUCKET}${props.image.src})`,
          backgroundSize: props.image.size || "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: props.image.align,
        }}
      >
        {props.image.gradient && (
          <div
            style={{
              width: "100%",
              height: "100%",
              marginLeft: -1,
              backgroundImage:
                "linear-gradient(90deg, rgba(17,20,25,1) 0%, rgba(17,20,25,0) 100%)",
            }}
          ></div>
        )}
      </div>
    </TextWithContentSlide>
  );
};

export const TextWithVideoSlide = (props) => {
  const [showModal, setShowModal] = useState(false);

  React.useEffect(() => {
    if (showModal) {
      document.getElementById("root").classList.add("blur");
    } else {
      document.getElementById("root").classList.remove("blur");
    }
  }, [showModal]);

  return (
    <TextWithContentSlide {...props}>
      {showModal && (
        <Modal
          centered
          visible={showModal}
          onOk={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
          width={1080}
        >
          <div style={{ paddingBottom: "56.25%", position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <iframe
                frameborder="0"
                height="100%"
                width="100%"
                src={
                  props.video.source ||
                  `https://youtube.com/embed/${props.video.id}?autoplay=1&controls=0&showinfo=0&autohide=1`
                }
              ></iframe>
            </div>
          </div>
        </Modal>
      )}
      <div
        style={{
          position: "absolute",
          left: "45%",
          right: 0,
          top: 0,
          bottom: 0,
          backgroundImage: `url(${process.env.REACT_APP_S3_BUCKET}${props.video.thumbnail.source})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: props.video.thumbnail.align,
        }}
      >
        {props.video.thumbnail.gradient && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: -1,
              backgroundImage:
                "linear-gradient(90deg, rgba(17,20,25,1) 0%, rgba(17,20,25,0) 100%)",
            }}
          >
            <PlayCircleFilled
              style={{ color: "#FFF", fontSize: "90px", cursor: "pointer" }}
              onClick={() => {
                setShowModal(true);
              }}
            />
            <Typography.Paragraph
              style={{ color: "#FFF", marginTop: 5, marginBottom: 0 }}
            >
              Watch Video
            </Typography.Paragraph>
          </div>
        )}
      </div>
    </TextWithContentSlide>
  );
};

export const TextWithBackgroundImageSlide = (props) => {
  return (
    <TextWithContentSlide {...props}>
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundImage: `url(${process.env.REACT_APP_S3_BUCKET}${props.image.src})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: props.image.align,
          zIndex: -1,
        }}
      >
        {props.image.gradient && (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundImage:
                "linear-gradient(45deg, rgba(17,20,25,1) 0%, rgba(17,20,25,0) 100%)",
            }}
          ></div>
        )}
      </div>
    </TextWithContentSlide>
  );
};

export const CarouselSlide = (props) => {
  return (
    <div
      style={{
        height: props.topOffset ? "105vh" : "50vw",
      }}
    >
      {props.type === "TextWithImage" && (
        <TextWithImageSlide
          {...props.slideContent}
          topOffset={props.topOffset}
        />
      )}
      {props.type === "TextWithBackgroundImage" && (
        <TextWithBackgroundImageSlide
          {...props.slideContent}
          topOffset={props.topOffset}
        />
      )}
      {props.type === "TextWithVideo" && (
        <TextWithVideoSlide
          {...props.slideContent}
          topOffset={props.topOffset}
        />
      )}
      {props.type === "FullWidthYoutubeVideo" && (
        <FullWidthYoutubeVideo {...props.slideContent} />
      )}
    </div>
  );
};

export const Carousel = (props) => {
  return (
    <section>
      <LibCarousel {...props}>
        {props.slides.map((slide) => (
          <div>
            <CarouselSlide {...slide} topOffset={props.topOffset} />
          </div>
        ))}
      </LibCarousel>
    </section>
  );
};

export const Banner = (props) => {
  return (
    <section
      style={{ padding: "25px 0px", backgroundColor: "rgba(255,255,255,0.05)" }}
    >
      <Container fullWidth>
        <Row align="middle">
          <Col span={20}>
            <Space size="middle">
              <Typography.Paragraph
                style={{
                  fontSize: "16px",
                  color: "white",
                  margin: 0,
                  fontWeight: "bold",
                }}
              >
                {props.title}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ fontSize: "16px", margin: 0 }}>
                {props.description}
              </Typography.Paragraph>
            </Space>
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <Button type="primary" href={props.cta.link} size="large">
              {props.cta.text}
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export const SectionWithTitleDescription = (props) => (
  <section
    style={{
      padding: "100px 0px",
      background: props.lighter ? "rgba(255,255,255,0.05" : undefined,
    }}
  >
    <Container small>
      {props.miniHeader && (
        <Typography.Paragraph
          style={{
            fontSize: "14px",
            fontFamily: "SF Display",
            marginBottom: 10,
            letterSpacing: "1px",
            color: colors[0],
            textAlign: "center",
          }}
        >
          {props.miniHeader}
        </Typography.Paragraph>
      )}
      <Typography.Title
        style={{
          fontSize: "42px",
          fontFamily: "SF Display",
          color: "#FFF",
          marginBottom: 20,
          lineHeight: "1.1",
          marginTop: 0,
          textAlign: "center",
          letterSpacing: "-0.012em",
          fontWeight: "500",
        }}
      >
        {props.title}
      </Typography.Title>
      <p
        dangerouslySetInnerHTML={{ __html: props.description }}
        style={{
          fontSize: !props.title ? "42px" : "18px",
          fontFamily: !props.title ? "SF Display" : "SF Text",
          lineHeight: !props.title ? "1.125" : "1.75",
          marginTop: 0,
          letterSpacing: "-0.012em",
          fontWeight: "500",
          textAlign: "center",
          color: "#FFF",
          opacity: props.title ? 0.75 : 1,
          fontWeight: "400",
        }}
      ></p>
    </Container>
    <Container fullWidth>{!props.outsideContainer && props.children}</Container>
    {props.outsideContainer && props.children}
  </section>
);

export const FullWidthImage = (props) => {
  return <img {...props} src={`${process.env.REACT_APP_S3_BUCKET}${props.source}`} width="100%" />;
};

export const FullWidthYoutubeVideo = (props) => {
  return (
    <iframe
      frameborder="0"
      height="100%"
      width="100%"
      src={`https://youtube.com/embed/${props.id}?autoplay=1&controls=0&showinfo=0&autohide=1`}
    ></iframe>
  );
};

export const Blurb = (props) => {
  return (
    <div style={{ padding: 25, textAlign: "center" }}>
   { (  props.image && <img
        src={`${process.env.REACT_APP_S3_BUCKET}${props.image}`}
        width={50}
        className="blurb-image"
        style={{ marginBottom: 25 }}
      />)}
      <Typography.Title
        style={{
          fontSize: "18px",
          color: "#FFF",
          fontFamily: "SF Display",
          textAlign: "center",
          marginTop: 0,
          fontWeight: "500",
        }}
      >
        {props.title}
      </Typography.Title>
      <Typography.Paragraph
        style={{
          fontSize: "18px",
          textAlign: "center",
          marginTop: 0,
        }}
      >
        {props.description}
      </Typography.Paragraph>
    </div>
  );
};

export const BlurbGrid = (props) => {
  return (
    <Row gutter={[25, 25]}>
      {props.blurbs.map((blurb) => (
        <Col span={props.elementSize}>
          <Blurb {...blurb} />
        </Col>
      ))}
    </Row>
  );
};

export const Spacer = () => {
  return <div style={{ height: 25 }} />;
};

export const Slide = (props) => (
  <div style={{ height: "40vw" }}>
    <TextWithContentSlide {...props}>
      <div
        style={{
          position: "absolute",
          left: props.right ? "-5%" : "40%",
          right: !props.right ? 0 : "45%",
          top: 0,
          bottom: 0,
          backgroundImage: `url(${process.env.REACT_APP_S3_BUCKET}${props.image.src})`,
          backgroundSize: props.image.size || "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: props.image.align,
        }}
      >
        {props.image.gradient && (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundImage:
                "linear-gradient(90deg, rgba(17,20,25,1) 0%, rgba(17,20,25,0) 100%)",
            }}
          ></div>
        )}
      </div>
    </TextWithContentSlide>
  </div>
);

export const ArticleList = (props) => {
  const [list, setList] = useState([]);

  React.useEffect(() => {
    Axios.get(`${process.env.REACT_APP_S3_BUCKET}${props.source}`).then((l) => {
      setList(l.data.articles);
    });
  }, [props.source]);

  return (
    <section
      style={{
        padding: props.removeContainer ? "50px 0px" : "100px 0px",
        background: props.lighter ? "rgba(255,255,255,0.05" : undefined,
      }}
    >
      <Container fullWidth>
        <Typography.Title
          style={{
            fontSize: "42px",
            color: "#FFF",
            fontFamily: "SF Display",
            marginTop: 0,
            marginBottom: 20,
            fontWeight: "500",
          }}
        >
          {props.title}
        </Typography.Title>
        <Spacer />
        <Row gutter={[props.gutter || 25, props.gutter || 25]}>
          {list &&
            list.map((article) => (
              <Col span={props.elementSize}>
                <a href={article.link}>
                  <div
                    style={{
                      paddingBottom: "66%",
                      backgroundImage: `url(${process.env.REACT_APP_S3_BUCKET}${article.thumbnail})`,
                      backgroundSize: "cover",
                      marginBottom: 20,
                    }}
                  />
                  {article.miniHeader && (
                    <Typography.Paragraph
                      style={{
                        fontSize: "14px",
                        fontFamily: "SF Display",
                        marginBottom: 10,
                        letterSpacing: "1px",
                        color: colors[0],
                      }}
                    >
                      {article.miniHeader}
                    </Typography.Paragraph>
                  )}
                  <Typography.Title
                    style={{
                      fontSize: "18px",
                      color: "#FFF",
                      fontFamily: "SF Display",
                      marginTop: 0,
                      fontWeight: "500",
                    }}
                  >
                    {article.title}
                  </Typography.Title>
                  <p
                    dangerouslySetInnerHTML={{ __html: article.description }}
                    style={{
                      fontSize: "16px",
                      color: "rgba(255,255,255,0.5)",
                    }}
                  ></p>
                </a>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
};

export const ProductList = (props) => {
  const [list, setList] = useState([]);

  React.useEffect(() => {
    Axios.get(`${process.env.REACT_APP_S3_BUCKET}${props.source}`).then((l) => {
      setList(l.data.articles);
    });
  }, [props.source]);

  return (
    <section
      style={{
        padding: props.removeContainer ? "50px 0px" : "100px 0px",
        background: props.lighter ? "rgba(255,255,255,0.05" : undefined,
      }}
    >
      <Container fullWidth>
        <Typography.Title
          style={{
            fontSize: "32px",
            color: "#FFF",
            fontFamily: "SF Display",
            marginTop: 0,
            fontWeight: "500",
          }}
        >
          {props.title}
        </Typography.Title>
        <Spacer />
        <Row gutter={[props.gutter || 25, props.gutter || 25]}>
          {list &&
            list.map((article) => (
              <Col span={props.elementSize} style={{ opacity: article.isActive ? 1 : 0.2 }}>
                <a href={article.isActive ? article.link : "#"} style={{ pointerEvents: article.isActive ? 'all' : 'none' }}>
                  <div
                    style={{
                      paddingBottom: "125%",
                      backgroundImage: `url(${process.env.REACT_APP_S3_BUCKET}${article.thumbnail})`,
                      backgroundSize: "cover",
                      marginBottom: 20,
                      borderRadius: 10,
                    }}
                  />
                  {article.miniHeader && (
                    <Typography.Paragraph
                      style={{
                        fontSize: "14px",
                        fontFamily: "SF Display",
                        marginBottom: 10,
                        letterSpacing: "1px",
                        color: colors[0],
                      }}
                    >
                      {article.miniHeader}
                    </Typography.Paragraph>
                  )}
                  <Typography.Title
                    style={{
                      fontSize: "18px",
                      color: "#FFF",
                      fontFamily: "SF Text",
                      marginTop: 0,
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    {article.title}
                  </Typography.Title>
                  <p
                    dangerouslySetInnerHTML={{ __html: article.description }}
                    style={{
                      fontSize: "14px",
                      color: "rgba(255,255,255,0.5)",
                    }}
                  ></p>
                </a>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
};

export const Header = ({ setHamburgerMenu, showHamburgerMenu }) => {
  const history = useHistory();
  return (
    <header
      style={{
        left: 0,
        right: 0,
        top: 0,
        zIndex: 99,
        backgroundColor: "#000",
        position: "sticky",
        top: 0,
      }}
    >
      <div style={{ position: "relative" }}>

        <Logo style={{ backgroundColor: "transparent" }}>
          <div
            onClick={() => setHamburgerMenu(!showHamburgerMenu)}
            style={{
              padding: "0.8rem 0rem",
              margin: "0px 0.188rem",
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              opacity: 0.75,
            }}
          >
            <Hamburger color="#FFF" style={{ width: 20, height: 20 }} />
          </div>
          {MenuConfig.groups[0].items.map((s) => {
            return (
              <SubMenuItem
                activeClassName="activeSubMenu"
                to={s.linked || undefined}
              >
                <MenuText>{s.text}</MenuText>
              </SubMenuItem>
            );
          })}
        </Logo>
      </div>
    </header>
  );
};

const MediaList = (props) => {
  const [list, setList] = useState([]);

  React.useEffect(() => {
    Axios.get(`${process.env.REACT_APP_S3_BUCKET}${props.source}`).then((l) => {
      setList(l.data.articles);
    });
  }, [props.source]);

  return (
    <section
      style={{
        padding: props.removeContainer ? "50px 0px" : "100px 0px",
        background: props.lighter ? "rgba(255,255,255,0.05" : undefined,
      }}
    >
      <Container fullWidth={true}>
        <Typography.Title
          style={{
            fontSize: "42px",
            color: "#FFF",
            fontFamily: "SF Display",
            marginTop: 0,
            marginBottom: 20,
            fontWeight: "500",
          }}
        >
          {props.title}
        </Typography.Title>
        <Spacer />
        {list &&
          list.map((article) => (
            <a href={article.link}>
              <Row
                gutter={[props.gutter || 25, props.gutter || 25]}
                align="middle"
              >
                <Col span={4}>
               
                  <div
                    style={{
                      paddingBottom: "100%",
                      backgroundImage: `url("${process.env.REACT_APP_S3_BUCKET}/landing/img/${article.thumbnail}")`,
                      backgroundSize: "cover",
                      marginBottom: 20,
                    }}
                  />
                </Col>
                <Col span={18}>
                  {article.miniHeader && (
                    <Typography.Paragraph
                      style={{
                        fontSize: "14px",
                        fontFamily: "SF Display",
                        marginBottom: 10,
                        letterSpacing: "1px",
                        color: colors[0],
                      }}
                    >
                      {article.miniHeader}
                    </Typography.Paragraph>
                  )}
                  <Typography.Title
                    style={{
                      fontSize: "24px",
                      color: "#FFF",
                      fontFamily: "SF Display",
                      marginTop: 0,
                    }}
                  >
                    {article.title}
                  </Typography.Title>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: article.description,
                    }}
                    style={{
                      fontSize: "16px",
                      color: "rgba(255,255,255,0.5)",
                    }}
                  ></p>
                </Col>
              </Row>
            </a>
          ))}
      </Container>
    </section>
  );
};

export const PageWrapper = ({ showHamburgerMenu, children }) => (
  <div style={{ maxWidth: "calc(111vw - 10px)" }}>
    <ParentContainer>
      <Sidebar collapsed={!showHamburgerMenu}>
        <Link to="/">
          <Logo>
            <LogoName>
              <LogoStatic>P</LogoStatic>
              <LogoDynamic>ersonalize</LogoDynamic>
              <LogoStatic>
                <small>.</small>
                <span>AI</span>
              </LogoStatic>
            </LogoName>
          </Logo>
        </Link>
        <ScrollContainer>
          <Menu groups={LandingConfig.groups} linked />
        </ScrollContainer>
      </Sidebar>
      <Body noFlex>
        <PageContainer noPad>{children}</PageContainer>
      </Body>
    </ParentContainer>
  </div>
);

export const Components = {
  Carousel,
  Banner,
  SectionWithTitleDescription,
  FullWidthImage,
  BlurbGrid,
  Spacer,
  Slide,
  ArticleList,
  FullWidthYoutubeVideo,
  MediaList,
  ProductList,
};
