import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from './styles'
import { publishObjective } from '../../../services/diagnose'
import { getStatus } from '../../../services/pipeline'

const PipelinePublish = () => {
    const [status, setStatus] = useState()
    const [loading, setLoading] = useState(false)
    
    const statusChecker = () => {
        getStatus().then(response => {
            if(response && response.progress && response.progress.length){
                setStatus("running")
            }else{
                setStatus("available")
            }
        })
    }
    useEffect(()=>{
        statusChecker()
        window.setInterval(()=>{
            statusChecker()
        },30000)
    },[])
    

    const labels = {
        running: "In Progress",
        available: "Update Model",
    }

    if(loading){
        return (
            <Button type={"running"}>
                Triggering
            </Button>
        )
    }

    if(!status) return ""

    if(status == "running" || status === "triggered"){
        return (
            <Link to='/monitoring'>
                <Button type={status}>
                    {labels[status]}
                </Button>
            </Link>
        )
    }

    if(status == "available"){
        return (
            <Button type={status} onClick={()=>{ 
                setLoading(true)
                publishObjective().then(response => {
                    setLoading(false)
                    setStatus("triggered")
                })
            }}>
                {labels[status]}
            </Button>
        )
    }
}

export default PipelinePublish