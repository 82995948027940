import React from 'react'
import styled, { keyframes, css } from 'styled-components'

const flash = keyframes`
    50% {
        opacity: 0;
    }
`;

const animation = css`
    animation: ${flash} 1s linear infinite;
`

export const Button = styled.button`
    padding: 7px 15px;
    border: 2px solid #15afd0;
    background: ${props => props.type === "running" ? "transparent" : "transparent"};
    margin: 0;
    font-size: 13px;
    line-height: 14px;
    font-weight: 200;
    border-radius: 10px;
    color:#FFF;
    padding-left: 30px;
    position: relative;
    cursor:pointer;

    &:before{
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -5px;
        left: 10px;
        width: 10px;
        height: 10px;
        background: ${props => props.type === "running" ? "#15afd0" : "green"};
        ${props => props.type !== "available" ? animation : ""}
        border-radius: 5px;
    }
`