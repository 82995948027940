import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory, Link } from "react-router-dom";

import { Header, Components, PageWrapper } from "./builder-components";
import {
  Body,
  Container,
  Logo,
  LogoDynamic,
  LogoName,
  LogoStatic,
  PageContainer,
  ScrollContainer,
  Sidebar,
} from "./styles";
import Menu from "../../components/menu";
import { LandingConfig as MenuConfig } from "./config";

export default () => {
  const [sections, setSections] = useState([]);

  const [showHamburgerMenu, setHamburgerMenu] = useState(false);

  React.useEffect(() => {
    Axios.get(`${process.env.REACT_APP_S3_BUCKET}/landing/configs/landing.json`).then((r) => {
      
      setSections(r.data.sections);
    });
  }, []);

  const history = useHistory();

  return (
    <PageWrapper showHamburgerMenu={showHamburgerMenu}>
      <Header
        showHamburgerMenu={showHamburgerMenu}
        setHamburgerMenu={setHamburgerMenu}
      />
      <div style={{ maxWidth: "calc(111vw - 67px)" }}>
      
        {sections?.map((section, index) => {
          const Component = Components[section.component];
          const content = section.content;

          return (
            <Component {...content} lighter={index % 2 !== 0}>
              {section.childs &&
                section.childs.map((child) => {
                  const ChildComponent = Components[child.component];
                  const childContent = child.content;

                  return <ChildComponent {...childContent} />;
                })}
            </Component>
          );
        })}
      </div>
    </PageWrapper>
  );
};
