import { Dashboard } from "styled-icons/octicons/Dashboard";
import { Database } from "styled-icons/feather/Database";
import { MakeGroup } from "styled-icons/icomoon/MakeGroup";
import { Input } from "styled-icons/material/Input";
import { Coupon2 } from "styled-icons/remix-line/Coupon2";
import { TestTube } from "styled-icons/boxicons-regular/TestTube";
import { Station } from "styled-icons/boxicons-regular/Station";
import { Monitor } from "styled-icons/feather/Monitor";
import { BookOpen } from "styled-icons/boxicons-regular/BookOpen";
import { Admin } from "styled-icons/remix-line/Admin";
import * as Dash from "styled-icons/remix-line/Dashboard";
import { Link } from "styled-icons/boxicons-regular/Link";
import { Bulb } from "@styled-icons/boxicons-regular/Bulb";

export const LandingConfig = {
  groups: [
    {
      name: "Quick Links",
      items: [
        {
          text: "Product",
          icon: Dash.Dashboard,
          linked: "/product",
          defaultRoute: "/",
          subMenu: [],
        },
        {
          text: "Research",
          icon: BookOpen,
          linked: "/research",
          defaultRoute: "/",
          subMenu: [],
        }
      ],
    },
  ],
};

export const MenuConfig = {
  groups: [
    {
      name: "Executive",
      items: [
        {
          text: "At a Glance",
          icon: Dash.Dashboard,
          linked: "/dashboard",
          defaultRoute: "/opportunities",
          subMenu: [
            {
              name: "Opportunities",
              linked: "/opportunities",
              key: "Opportunities",
            },
            { name: "Results", linked: "/results", key: "Results" },
          ],
        },
      ],
    },
    {
      name: "Data Science",
      items: [
        {
          text: "Data",
          icon: Database,
          linked: "/data",
          defaultRoute: "/import",
          subMenu: [
            { name: "Import", linked: "/import", key: "DataImport" },
            /*{ name: "Merge", linked: "/merge", key: "DataMerge" },
                        { name: "Explore", linked: "/explore", key:"DataExplore" },
                        { name: "Transform", linked: "/transform", key:"DataTransform" },*/
            { name: "Dictionary", linked: "/view", key: "DataDictionary" },
          ],
        },
        {
          text: "Business Inputs & Diagnosis",
          icon: Input,
          linked: "/diagnose",
          defaultRoute: "/objective",
          subMenu: [
            {
              name: "Objective",
              linked: "/objective",
              key: "Objective",
              type: "Component",
              componentKey: "ObjectiveMenu",
            },
            {
              name: "Feature Engineering",
              linked: "/features",
              key: "FeatureEngineering",
            },
            {
              name: "Univariate Analysis",
              linked: "/univariate",
              key: "UnivariateAnalysis",
            },
            {
              name: "Bivariate & Correlation",
              linked: "/bivariate",
              key: "BivariateCorrelation",
            },
            {
              name: "Auto Dashboard",
              linked: "/dashboard",
              key: "AutoDashboard",
            },
          ],
          actions: [
            {
              name: "PipelinePublish",
              key: "PipelinePublish",
              type: "Component",
              componentKey: "PipelinePublish",
            },
          ],
        },
        {
          text: "Classification",
          icon: MakeGroup,
          linked: "/classification",
          defaultRoute: "/hierarchy",
          subMenu: [
            { name: "Hierarchy", linked: "/hierarchy", key: "Segmentation" },
            {
              name: "Renewals & Retentions",
              linked: "/renewals-retentions",
              key: "RenewalsRetentions",
            },
            { name: "Elasticity", linked: "/elasticity", key: "Elasticity" },
            {
              name: "Explore Segments",
              linked: "/segments",
              key: "ExploreSegments",
            },
            {
              name: "Explore Customers",
              linked: "/customers",
              key: "ExploreCustomers",
            },
          ],
        },
        {
          text: "Recommendation",
          icon: Coupon2,
          linked: "/recommendation",
          defaultRoute: "/summary",
          subMenu: [
            {
              name: "Summary",
              linked: "/summary",
              key: "RecommendationSummary",
            },
            //{ name: "Offers", linked: "/offers", key:"OfferRecommendation"},
            //{ name: "Items", linked: "/items", key:"ItemRecommendation" },
            {
              name: "Items & Offers",
              linked: "/items-offers",
              key: "ItemOffer",
            },
            { name: "Diagnostics", linked: "/diagnostics", key: "Diagnostics" },
          ],
        },
        {
          text: "A/B Testing",
          icon: TestTube,
          linked: "/testing",
          defaultRoute: "/tests",
          subMenu: [
            { name: "Tests", linked: "/tests", key: "ABTestList" },
            { name: "Measure", linked: "/measure/:id?", key: "ABTestResults" },
            { name: "", linked: "/build/:id?", key: "BuildTest" },
            { name: "", linked: "/analyze/:id?", key: "ABTestAnalyse" },
          ],
        },
      ],
    },
    {
      name: "Operationalization",
      items: [
        {
          text: "Integrations",
          icon: Link,
          linked: "/integrations",
          defaultRoute: "/all",
          subMenu: [
            { name: "Integrations", linked: "/all", key: "Integrations" },
          ],
        },
        {
          text: "API Out",
          icon: Station,
          linked: "/operationalization",
          defaultRoute: process.env.REACT_APP_MOCK ? "/food-tech" : "/realtime",
          subMenu: [
            { name: "Food Tech", linked: "/food-tech", key: "SweetGreens" },
            { name: "DTC Recommendations", linked: "/dtc", key: "StitchFix" },
            { name: "Hospitality", linked: "/hospitality", key: "Hospitality" },
            { name: "H2", linked: "/h2", key: "Hospitality2" },
            { name: "Realtime Demo", linked: "/realtime", key: "DemoPassive" },
          ].filter((r) =>
            process.env.REACT_APP_MOCK
              ? r.key !== "DemoPassive"
              : r.key === "DemoPassive"
          ),
        },
      ],
    },
    {
      name: "Administration",
      last: true,
      items: [
        {
          text: "Monitoring",
          icon: Monitor,
          linked: "/monitoring",
          defaultRoute: "/pipelines",
          subMenu: [
            {
              name: "Execution Pipelines",
              linked: "/pipelines",
              key: "PipelineManagement",
            },
          ],
        },
        {
          text: "Infrastructure",
          icon: Admin,
          linked: "/infrastructure",
          defaultRoute: "/pipeline",
          subMenu: [{ name: "Pipeline", linked: "/pipeline" }],
        },
      ],
    },
  ],
};
