import React from 'react'

import styled from 'styled-components'

export const MuteCartWrapper = styled.div`
    position: relative;
`

export const MuteCartCount = styled.div`
    position: absolute;
    margin:0;
    width: 13px;
    height: 13px;
    background: #15afd0;
    border-radius: 10px;
    color:#FFF;
    right: -7px;
    top:0px;
    margin-top: -3px;
    font-size: 9px;
    line-height: 13px;
    text-align: center;
`