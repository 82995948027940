import React, { useState, useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { CaretDown } from "styled-icons/boxicons-regular/CaretDown";
import {
  ObjectiveDropDownWrapper,
  ObjectiveSelected,
  ObjectiveIcon,
  ObjectiveMenu,
  ObjectiveItem,
  SubMenuItem,
} from "./styles";
import { getObjectives } from "../../../services/diagnose";

const ObjectiveSelector = ({}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [itemList, setItemList] = useState(null);

  useEffect(() => {
    getObjectives().then((objectives) => {
      const objectivesList = Object.keys(objectives)
        .map((objId) => ({ ...objectives[objId], id: objId }))
        .filter((obj) => obj.is_active);
      setItemList(objectivesList);
      if (objectivesList.length === 1 || !selectedValue) {
        setSelectedValue(objectivesList[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedValue) {
      window.localStorage.setItem(
        "p.ai_objective_selected",
        JSON.stringify(selectedValue)
      );
      var event = new window.Event("objectiveChanged");
      window.dispatchEvent(event);
    }
  }, [selectedValue]);

  return (
    <Switch>
      <Route exact path="/diagnose/objective">
        <SubMenuItem>Objectives</SubMenuItem>
      </Route>
      <Route path="/diagnose">
        <SubMenuItem>
          <ObjectiveDropDownWrapper
            onClick={() => {
              setShowDropDown(!showDropDown);
            }}
          >
            <ObjectiveSelected>
              {
                (selectedValue || { objective_name: "Select Objective" })
                  .objective_name
              }
            </ObjectiveSelected>
            <ObjectiveIcon>
              <CaretDown color="#FFFFFF80" />
            </ObjectiveIcon>
            {showDropDown && (
              <ObjectiveMenu>
                {itemList &&
                  itemList.map((item) => (
                    <ObjectiveItem
                      onClick={() => {
                        setSelectedValue(item);
                        setShowDropDown(false);
                      }}
                    >
                      {item.objective_name}
                    </ObjectiveItem>
                  ))}
                <Link to={"/diagnose/objective"}>
                  <ObjectiveItem>View All</ObjectiveItem>
                </Link>
              </ObjectiveMenu>
            )}
          </ObjectiveDropDownWrapper>
        </SubMenuItem>
      </Route>
    </Switch>
  );
};

export default ObjectiveSelector;
