import React, { useEffect, useState } from "react";

const withObjective = (Component) => (props) => {
  const [objective, setObjective] = useState(null);
  const [loading, setLoading] = useState(false);

  const objectiveChangedHandler = () => {
    setLoading(true);
    let selectedObjective = window.localStorage.getItem(
      "p.ai_objective_selected"
    );
    if (selectedObjective) {
      selectedObjective = JSON.parse(selectedObjective);
      setObjective(selectedObjective);
      setLoading(false);
    }
  };

  useEffect(() => {
    objectiveChangedHandler();
    window.addEventListener("objectiveChanged", objectiveChangedHandler, false);
    return () => {
      window.removeEventListener("objectiveChanged", objectiveChangedHandler);
    };
  }, []);

  if (loading) return "Loading";

  return <Component {...props} objective={objective} />;
};

export default withObjective;
