import axios from 'axios'

export const getFlowChart = () => new Promise((resolve, reject) => {
    axios
        .get(`${process.env.REACT_APP_MICROSERVICE_HOST}/pipeline/GetFlowChart`, { headers: {"x-api-key": process.env.REACT_APP_API_KEY}})
        .then((response) => {  
            const responseData = JSON.parse(response.data)
            
            console.log(responseData)
            let pipeline = {
                version: responseData.version,
                components: {},
                pipeline_graph: {
                    nodes: [],
                    edges: []
                }
            }

            responseData.components.forEach(component => {
                pipeline.components[component.id] = component
            })

            pipeline.pipeline_graph.nodes = responseData.nodes.map(node => {
                let newNode = {...node}
                newNode.instanceOf = null
                newNode.instance_of = node.instanceOf.name
                return newNode
            })

            pipeline.pipeline_graph.edges = responseData.paths.map(path => {
                let newPath = {...path}
                newPath.source = path.source.id
                newPath.target = path.target.id
                return newPath
            })
            resolve([pipeline])
        })
        .catch(err => {
            resolve([])
        })
})

export const getStatus = (version) => new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_MICROSERVICE_HOST}/pipeline/GetAllJobStatus`, { headers: {"x-api-key": process.env.REACT_APP_API_KEY}}).then((response) => {
        resolve({
            progress: response.data.filter(r=>r.status === "IN_PROGRESS").map(r=>r.component.id),
            highlights: response.data.filter(r=>r.status === "COMPLETED").map(r=>r.component.id),
            error: response.data.filter(r=>r.status === "ERROR").map(r=>r.component.id),
        })
    })
})