import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AuthLayout from "./library/layouts/auth-layout";

import styled, { createGlobalStyle } from "styled-components";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "styled-react-modal";





const GlobalStyle = createGlobalStyle`
/*---------------------------------------------------------------------------*
* SF UI Display
*---------------------------------------------------------------------------*/

/** Black */
@font-face {
 font-family: "SF Display";
 font-weight: 900;
 src: url("./fonts/SF-UI-Display-Black.otf");
}

/** Bold */
@font-face {
 font-family: "SF Display";
 font-weight: 700;
 src: url("./fonts/SF-UI-Display-Bold.otf");
}

/** Heavy */
@font-face {
 font-family: "SF Display";
 font-weight: 800;
 src: url("./fonts/SF-UI-Display-Heavy.otf");
}

/** Light */
@font-face {
 font-family: "SF Display";
 font-weight: 200;
 src: url("./fonts/SF-UI-Display-Light.otf");
}

/** Medium */
@font-face {
 font-family: "SF Display";
 font-weight: 500;
 src: url("./fonts/SF-UI-Display-Medium.otf");
}

/** Regular */
@font-face {
 font-family: "SF Display";
 font-weight: 400;
 src: url("./fonts/SF-UI-Display-Regular.otf");
}

/** Semibold */
@font-face {
 font-family: "SF Display";
 font-weight: 600;
 src: url("./fonts/SF-UI-Display-Semibold.otf");
}

/** Thin */
@font-face {
 font-family: "SF Display";
 font-weight: 300;
 src: url("./fonts/SF-UI-Display-Thin.otf");
}

/** Ultralight */
@font-face {
 font-family: "SF Display";
 font-weight: 100;
 src: url("./fonts/SF-UI-Display-Ultralight.otf");
}

/*---------------------------------------------------------------------------*
* SF UI Text
*---------------------------------------------------------------------------*/

/** Bold */
@font-face {
 font-family: "SF Text";
 font-weight: 700;
 src: url("./fonts/SF-UI-Text-Bold.otf");
}

/** Bold Italic */
@font-face {
 font-family: "SF Text";
 font-weight: 700;
 font-style: italic;
 src: url("./fonts/SF-UI-Text-BoldItalic.otf");
}

/** Heavy */
@font-face {
 font-family: "SF Text";
 font-weight: 800;
 src: url("./fonts/SF-UI-Text-Heavy.otf");
}

/** Heavy Italic */
@font-face {
 font-family: "SF Text";
 font-weight: 800;
 font-style: italic;
 src: url("./fonts/SF-UI-Text-HeavyItalic.otf");
}

/** Light */
@font-face {
 font-family: "SF Text";
 font-weight: 200;
 src: url("./fonts/SF-UI-Text-Light.otf");
}

/** Light Italic */
@font-face {
 font-family: "SF Text";
 font-weight: 200;
 font-style: italic;
 src: url("./fonts/SF-UI-Text-LightItalic.otf");
}

/** Medium */
@font-face {
 font-family: "SF Text";
 font-weight: 500;
 src: url("./fonts/SF-UI-Text-Medium.otf");
}

/** Medium Italic */
@font-face {
 font-family: "SF Text";
 font-weight: 500;
 font-style: italic;
 src: url("./fonts/SF-UI-Text-MediumItalic.otf");
}

/** Regular */
@font-face {
 font-family: "SF Text";
 font-weight: 400;
 src: url("./fonts/SF-UI-Text-Regular.otf");
}

/** Regular Italic */
@font-face {
 font-family: "SF Text";
 font-weight: 400;
 font-style: italic;
 src: url("./fonts/SF-UI-Text-RegularItalic.otf");
}

/** Semibold */
@font-face {
 font-family: "SF Text";
 font-weight: 600;
 src: url("./fonts/SF-UI-Text-Semibold.otf");
}

/** Semibold Italic */
@font-face {
 font-family: "SF Text";
 font-weight: 600;
 font-style: italic;
 src: url("./fonts/SF-UI-Text-SemiboldItalic.otf");
}

/** Ultrathin */
@font-face {
 font-family: "SF Text";
 font-weight: 100;
 src: url("./fonts/SF-UI-Text-Ultrathin.otf");
}

/** Ultrathin Italic */
@font-face {
 font-family: "SF Text";
 font-weight: 100;
 font-style: italic;
 src: url("./fonts/SF-UI-Text-UltrathinItalic.otf");
}
`;

const SpecialModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 111vw;
  height: 111vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.65);
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function App() {

  const [login, setLogin] = useState(false)

  const verifyPassword = (e) => {

    console.log('aayush', document.getElementById('password').value)

    let paswords = document.getElementById('password').value;

    let passwords = [

      "allyouraibelongstous",

      "Personalize02",

      "aieuphoria23"

    ]

    if (passwords.includes(paswords)) {

      localStorage.setItem('login', 'true')

      setLogin(true)
    }
  }

  return (
    <>
      <GlobalStyle />
      <ThemeProvider
        theme={{
          gutter: {
            inner: 20,
          },
          colors: {
            primary: "#15afd0",
            secondary: "#15afd0",
            default: "#ffffff",
            success: "#00FF00",
            background: "#111419",
            muted: "#FFFFFF",
          },
          borderRadius: 10,
          background: "#000",
          fontSizes: {
            xlarge: 36,
            large: 21,
            medium: 16,
            default: 14,
            small: 11,
          },
          modal: {
            background: "#181b20",
          },
        }}
      >
        <ModalProvider backgroundComponent={SpecialModalBackground}>
          {localStorage.getItem('login') && localStorage.getItem('login') == 'true' ?
            <AuthLayout>
             {({ pageName }) => {
            return <></>;
          }}
            </AuthLayout> :
            <div style={{

              display: "flex",

              justifyContent: "center",

              alignItems: "center",

              minHeight: "100vh"

            }}>

              <div>

                <div style={{

                  margin: "0",

                  "color": "hsla(0,0%,100%,.85)",

                  fontSize: "14px",

                  fontFamily: "SF Text",

                  fontVariant: "tabular-nums",

                  lineHeight: "1.5715",

                  backgroundColor: "#111419",





                }} > This page is password protected. </div>

                <input style={{




                  background: "#2d2f31",

                  height: "30px",

                  border: "1px solid #3f4c58 !important",

                  borderRadius: "5px",

                  padding: "2px",

                  width: "100%"

                }} type="password" placeholder="Password" id="password"  ></input>

                <button style={{ marginTop: "10px", display: "flex", float: "right" ,    color: "#fff",
    borderColor: "#0988ab",
    background: "#0988ab " , minWidth: "100px" , height: "32px",
    padding: "4px 15px",
    fontSize: "14px",
    borderRadius: "10px"}} onClick={e => verifyPassword(e)} > Submit </button>

              </div>

            </div>
          }
        </ModalProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
